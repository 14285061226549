import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';
import TextHeadingWrapper from '../../components/layout/TextHeadingWrapper';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Separator from '@rotaready/frecl/build/Separator';
import LinkWithArrow from '@rotaready/frecl/build/LinkWithArrow';
import A from '@rotaready/frecl/build/A';

const isBrowser = typeof window !== 'undefined';

const HeroWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  background-repeat: no-repeat;

  background-position: center bottom;
  background-size: 100% auto;
  background-image: url(${props => props.backgroundMobile});

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    background-position: right calc(40% - 200px) top;
    background-size: auto 100%;
    background-image: url(${props => props.backgroundDesktop});
    transform: translate3d(0, -80px, 0);
  }

  @media (min-width: ${ViewportBreakpoints.tabletLandscapeUpper}) {
    background-position: right calc(40% - 150px) top;
  }

  @media (min-width: ${ViewportBreakpoints.desktopUpper}) {
    background-position: right calc(40% - 265px) top;
  }
`;

const Hero = styled.div`
  padding: 80px 0 470px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 210px 0 170px 0;
  }
`;

const HeroInnerContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
  }
`;

const HeroBody = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    width: 40%;
  }
`;

const HeroTextWrapper = styled.div`
  margin-bottom: 20px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 40px;
  }
`;

const TitleWrapper = styled.div`
  text-align: ${props => props.textAlign};
  margin: 40px 0 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
  }
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const Benefit = styled.div`
  flex: 0 1 auto;
  margin: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 1 calc(33% - 76px);
    margin: 50px 76px 50px 0;
  }
`;

const BenefitTitle = styled(H3).attrs({
  uistyle: 'brand160',
})``;

const BenefitBody = styled.div`
  padding-top: 20px;
`;

const BenefitDesc = styled(Text).attrs({
  size: 'xl',
})``;

const GreyWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.grey10};
`;

const PositionsContainer = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  margin: 30px 0;
  padding: 30px 50px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 60px 100px 0 100px;
    padding: 60px 100px;
  }
`;

const JobTable = styled.div`
  display: flex;
  flex-direction: column;
`;

const JobRow = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin: 10px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
    align-items: center;
  }
`;

const JobTitleCell = styled.div`
  flex: 1 1 auto;
`;

const JobLocationCell = styled.div`
  flex: 1 1 auto;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 0 150px;
  }
`;

const JobApplyCell = styled.div`
  flex: 1 1 auto;
  padding-top: 10px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 0 0 150px;
    padding: 0;
  }
`;

const JobTitle = styled(Text).attrs({
  uistyle: 'brand160',
  weight: 600,
  size: 'lg',
})``;

const JobLocation = styled(Text)``;

const ButtonBox = styled.div`
  text-align: center;

  button {
    margin: 10px;
  }
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
};

class CareersPage extends React.Component {
  constructor(props) {
    super(props);

    this.positionsTopRef = React.createRef();
  }

  goToPositions = () => {
    const scrollTo = this.positionsTopRef.current.offsetTop;

    if (isBrowser) {
      window.scroll({ top: scrollTo, left: 0, behavior: 'smooth' });
    }
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="Careers - Rotaready"
          description="Working at Rotaready means continuing the innovation of an indispensable platform, built by a world-class team. Join us in our London Victoria office."
          url="careers"
        />
        <HeroWrapper
          backgroundDesktop={data.heroBackgroundDesktop.childImageSharp.fixed.src}
          backgroundMobile={data.heroBackgroundMobile.childImageSharp.fixed.src}
        >
          <ResponsiveContainer>
            <Hero>
              <HeroInnerContainer>
                <HeroBody>
                  <HeroTextWrapper>
                    <H1 uistyle="brand160" text="Careers" />
                  </HeroTextWrapper>

                  <HeroTextWrapper>
                    <H4 uistyle="grey">
                      We learn obsessively, we're relentless in the pursuit of
                      innovation and we relish the challenge of making complex
                      things simple.
                    </H4>
                  </HeroTextWrapper>

                  <HeroTextWrapper>
                    <H4 uistyle="grey">
                      Working at Rotaready means continuing the innovation of an
                      indispensable platform, built by a world-class team.
                    </H4>
                  </HeroTextWrapper>

                  <Button uistyle="primary" text="See open positions" size="lg" onClick={this.goToPositions} />
                </HeroBody>
              </HeroInnerContainer>
            </Hero>
          </ResponsiveContainer>
        </HeroWrapper>

        <ResponsiveContainer>
          <TitleWrapper textAlign="left">
            <H2 uistyle="brand160" text="Company benefits" />
          </TitleWrapper>

          <BenefitsContainer>
            <Benefit>
              <BenefitTitle text="Brand new Macbook" />

              <BenefitBody>
                <BenefitDesc text="We'll give you all the tools you need to do what you do best. Starting with your own Macbook Pro, 4K screen and accessories. Plus some Rotaready swag waiting for you on your first day." />
              </BenefitBody>
            </Benefit>

            <Benefit>
              <BenefitTitle text="Choose your hours" />

              <BenefitBody>
                <BenefitDesc text="Some of us are night owls, others are early birds and all of our schedules vary. No need to ask permission, just work the hours that suit you best - nobody's clock watching here." />
              </BenefitBody>
            </Benefit>

            <Benefit>
              <BenefitTitle text="Personal development" />

              <BenefitBody>
                <BenefitDesc text="We believe in continual growth and development, not just for our product but for our people too. We'll give you a budget to learn, attend events and experiment to your heart's content." />
              </BenefitBody>
            </Benefit>

            <Benefit>
              <BenefitTitle text="Drinks & snacks" />

              <BenefitBody>
                <BenefitDesc text="We have fresh fruit delivered every week, a range of delicious Tea And The Gang teas and Yallah Coffee. Not to mention two fridges full of soft drinks, wine and an eclectic mix of craft beers." />
              </BenefitBody>
            </Benefit>

            <Benefit>
              <BenefitTitle text="Private healthcare" />

              <BenefitBody>
                <BenefitDesc text="Your health and well-being is paramount. That's why we've partnered with Vitality to give you access to the best private healthcare in the country." />
              </BenefitBody>
            </Benefit>

            <Benefit>
              <BenefitTitle text="Dog-friendly office" />

              <BenefitBody>
                <BenefitDesc text="Here at Rotaready every day is bring your dog to work day. We encourage you to bring your best friend to work with you... because a dog filled workplace is a happy one." />
              </BenefitBody>
            </Benefit>
          </BenefitsContainer>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <ContentWrapper>
            <GatsbyImage
              image={data.collagePhoto.childImageSharp.gatsbyImageData}
              alt="Rotaready employees at work"
            />
          </ContentWrapper>
        </ResponsiveContainer>

        <GreyWrapper>
          <ResponsiveContainer>
            <ContentWrapper>
              <TitleWrapper textAlign="center" innerRef={this.positionsTopRef}>
                <H2 uistyle="brand160" text="Current openings" />
              </TitleWrapper>

              <PositionsContainer>
                <H3 uistyle="brand160" text="Engineering" />
                <Separator />
                <JobTable>
                  <JobRow>
                    <JobTitleCell>
                      <JobTitle text="Full stack engineer" />
                    </JobTitleCell>
                    <JobLocationCell>
                      <JobLocation text="Victoria, London" />
                    </JobLocationCell>
                    <JobApplyCell>
                      <LinkWithArrow
                        direction="right"
                        href="mailto:jobs@rotaready.com?subject=Full stack engineer"
                        text="Apply by email"
                        render={props => <A {...props} />}
                      />
                    </JobApplyCell>
                  </JobRow>
                  <JobRow>
                    <JobTitleCell>
                      <JobTitle text="Machine learning engineer" />
                    </JobTitleCell>
                    <JobLocationCell>
                      <JobLocation text="Victoria, London" />
                    </JobLocationCell>
                    <JobApplyCell>
                      <LinkWithArrow
                        direction="right"
                        href="mailto:jobs@rotaready.com?subject=Machine learning engineer"
                        text="Apply by email"
                        render={props => <A {...props} />}
                      />
                    </JobApplyCell>
                  </JobRow>
                </JobTable>
              </PositionsContainer>
            </ContentWrapper>
          </ResponsiveContainer>
        </GreyWrapper>

        <ResponsiveContainer>
          <ContentWrapper>
            <TextHeadingWrapper>
              <H2 text="Want to know more?" uistyle="brand160" />
            </TextHeadingWrapper>

            <TextHeadingWrapper>
              <Text text="Meet the people who make Rotaready and check out our story." size="xl" />
            </TextHeadingWrapper>

            <ButtonBox>
              <Link to="/team">
                <Button uistyle="primary" text="Meet the team" size="lg" />
              </Link>

              <Link to="/about">
                <Button uistyle="primary" text="About us" size="lg" ghost borderless />
              </Link>
            </ButtonBox>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default CareersPage;

export const query = graphql`
  query {
    heroBackgroundDesktop: file(relativePath: { eq: "misc/hero-careers-desktop.png" }) {
      childImageSharp {
        fixed(height: 1580, width: 2754, quality: 30) {
          src
        }
      }
    }

    heroBackgroundMobile: file(relativePath: { eq: "misc/hero-careers-mobile.png" }) {
      childImageSharp {
        fixed(height: 894, width: 750, quality: 30) {
          src
        }
      }
    }

    collagePhoto: file(relativePath: { eq: "misc/careers-collage.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100)
      }
    }
  }
`;
